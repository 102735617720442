/* WHAT'S NEW */

.Whats-New-Today i {
  color: #FFF !important;
  font-size: 40px !important;
  margin-right: 10px;
  margin-bottom: 0 !important;
}

.Whats-New-Today h2 {
  margin-bottom: 10px !important
}

.Whats-New-Today p {
  text-align: left;
  color: #eee;
  font-size: 20px !important;
}

/* HERO */

.Hero {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(87, 4, 134, 0.9);
  }

  .Hero-Loading {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(87, 4, 134, 0.9);
    min-height: 555px;
  }

  .Hero-Main-Section,
  .Hero-Loading {
    flex: 70% 0;
    display: flex;
    justify-content: center;
    min-height: 555px;
    background-size: cover;
    align-items: center;
  }

  .Hero-Register-Business,
  .Hero-Checkout-Business {
    flex: 30% 0;
    display: flex;
    position: relative;
    min-height: 555px;
    background: #5a7d19;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: -20px 20px 0px #5aaa43;
    border-radius: 0 0 0 150px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .Hero-Register-Business i {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 70px;
    z-index: 0;
    color: rgba(0,0,0,0.1);
  }

  .Dynamic-Business-Listing-Count {
    display: flex;
    font-size: 23px;
    font-style: italic;
    color: #e4ffb4;
    text-align: left;
  }

  .Hero-Register-Business input, .Hero-Register-Business select, .Hero-Register-Business button {
    background: #FFF;
    font-family: "Outfit";
    font-size: 1.3em;
    padding: 10px 25px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    border: 0;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
  }

  .Hero-Register-Business-Error-Message {
    background: #d76a1c;
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .Hero-Register-Business button {
    background-image: linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88));
    color: #FFF;
    cursor: pointer;
  }

  .Hero-Register-Business button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .Hero .Hero-Register-Business h2,
  .Hero .Hero-Checkout-Business h2 {
    color: #FFF;
    font-size: 2em;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
  }

  .Hero .Hero-Checkout-Business h2 {
    text-align: center;
  }

  .Hero .Hero-Checkout-Business a {
    background: #FFF;
    font-family: "Outfit";
    font-size: 1.3em;
    padding: 10px 25px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    border: 0;
    font-style: normal;
    text-decoration: unset;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    background-image: linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88));
    color: #FFF;
    cursor: pointer;
  }

  .Hero .Hero-Checkout-Business p {
    color: #FFF;
    font-size: 21px;
  }

  .Hero .Hero-Checkout-Business i {
    color: gold;
    font-size: 80px;
    margin-bottom: 20px;
  }

  .Hero .Whats-New-Today-Inner {
    padding: 0 50px;
    text-align: center;
  }

  .Hero-Search-Content {
    padding: 0 50px;
    min-height: 420px;
  }

  .Hero-Search-Content .Field-Container {
    position: relative;
  }

  .Hero-Search-Content .Field-Container span {
    position: absolute;
    left: -460px;
    top: 0;
    width: 420px;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    background: #ffa6a6;
    border: 2px solid pink;
    line-height: 1em;
  }

  .Hero-Register-Business h2,
  .Hero-Register-Business input {
    display: flex;
    flex: 100% 0 0;
  }

  .error-message span {
    padding: 10px ;
    display: block;
    font-size: 16px;
  }

  .Hero-Register-Business .Get-Started-Input-Container {
    display: flex;
  }

  .Hero-Register-Business .Get-Started-Input-Container .Get-Started-Prefix-Select {
    display: flex;
    flex: 45% 0;
    border-radius: 20px 0 0 20px;
    background: #eee;
    font-size: 16px;
  }

  .Hero-Register-Business .Get-Started-Input-Container .Get-Started-Input {
    display: flex;
    flex: 55% 0;
    border-radius: 0 20px 20px 0;
    font-size: 16px;
  }

  
  .Hero-content {
    max-width: 750px;
    text-align: left;
    color: #fff;
    justify-content: center;
  }

  .Hero-content a {
    background: #fed600;
    padding: 11px 45px;
    font-size: 21px;
    text-decoration: none;
    color: #000;
    border-radius: 55px;
    font-family: "Outfit";
    align-items: center;
    margin: 0;
    display: inline-flex;
  }

  .Hero-content a:hover {
    background: #000;
    color: #FFF;
  }

  .Hero-content small { 
    font-size: 18px;
    color: #eee; 
  }


  .Hero-content a i {
    margin-left: 6px;
  }

  .Hero-content a:hover {
    background: #222;
    cursor: pointer;
  }

  .Hero-v1-left {
    max-width: 70%;
  }
  
  .Hero h1,
  .Hero-Loading h1 {
    font-size: 1.7rem;
    line-height: 1em;
    margin-bottom: 0.3em;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 900;
    text-shadow: 1px 1px 50px rgba(0,0,0,0.3)
  }

  .Hero-Main-Section, .Hero-Loading {
    flex: 70% 0;
    display: flex;
    justify-content: center;
    min-height: 555px;
    background-size: cover;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  
  .Hero-Featured-Media {
    flex: 45%;
    display: flex;
    color: #DBF6AC;
    flex-wrap: wrap;
    font-size: 17px;
    justify-content: center;
    font-family: "Outfit";
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .hero-featured-media {
    position: relative;
    width: 100%;
    height: 350px; /* Adjust to your desired height */
    overflow: hidden;
  }
  
  .slides {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .hero-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: fade 12s linear infinite;
  }

  .hero-slide.no-animation {
  animation: none !important;
  opacity: 1;
  }

  .Create-Business-Website .CreateBusinessWebsiteContainer {
      width: 100%;
      font-family: "Outfit";
      font-size: 35px;
      margin: 0;
  }
  
  .hero-slide:nth-child(1) {
    animation-delay: 0s;
  }
  
  .hero-slide:nth-child(2) {
    animation-delay: 6s;
  }
  
  .hero-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-slide span {
    position: absolute;
    bottom: 5px;
    left: 20px;
    color: white;
    font-size: 19px;
    font-family: "Outfit";
    background-color: #000;
    padding: 4px 22px;
    border-radius: 50px;
    border: 5px solid rgba(255,255,255,0.1);
  }
  
  @keyframes fade {
    0%, 6.66%, 50%, 56.66%, 100% {
      opacity: 0;
    }
    8.33%, 41.67% {
      opacity: 1;
    }
  }
  
  .Hero-Featured-Media img {
    width: 100%;
    box-sizing: border-box;
  }
  

  
  .Hero-content {
    text-align: left;
    color: #fff;
    justify-content: center;
    flex: 55% 0;
    position: relative;
    padding: 0 50px 0 10px;
    box-sizing: border-box;
    justify-content: right;
  }

  .Main-Heading-Subtitle {
    font-size: 1.7rem;
    display: flex;
    color: #def9af;
  }
  
  .Hero h2,
  .Hero-Loading h2 {
    font-size: 1.6em;
    line-height: 1.1em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 500;
    text-shadow: 1px 1px 100px rgba(0,0,0,0.3);
  }

  .Hero .divider,
  .Hero-Loading .divider {
    height: 3px;
    background: #a4ce59;
    width: 15%;
    margin: 1.2em 0;
  }
  
  .Hero p {
    font-size: 1.35em;
    margin-bottom: 2rem;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 300;
  }
  
  .Hero-cta {
    display: inline-block;
    background-color: #b5003b;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .Hero-cta:hover {
    background-color: #dd4175;
  }
  























  .sun {
    height: 70px;
    width: 70px;
    background-color: #ff8c1a;
    border-radius: 50%;
    position: absolute;
    left: 10%;
    transform: translateX(-50%);
    top: 10%;
    box-shadow: 0px 0px 20px 5px #ff8c1a;
    animation: sunset 25s forwards;
    z-index: 0;
  }
  
  .moon {
    background-color: unset;
    width: 70px;
    height: 70px;
    border-radius:
  50%;
    box-shadow: -20px 10px white;
    position: absolute;
    right: 3%;
    top: 2%;
    opacity: 0;
    animation:
  night 0.8s 15s forwards;
    z-index: 1;
  
  }
  
  .building {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    text-align: left;
  }

  .Home .Hero-Main-Section {
    position: relative;
    overflow: hidden;
  }
  
  .building img {
    width: 70%;
    height: auto;
    opacity: 0.1;
    text-align: left;
  }
  
  @keyframes sky {
    50% {
      background-color: #ffd700;
    }
    65% {
      background-color: #ffb90f;
    }
    85% {
      background-color: #b39800;
    }
    100% {
      background-color: #68228b;
    }
  }
  
  @keyframes sunset {
    100% {
      transform: translateY(750px);
    }
  }
  
  @keyframes night {
    100% {
      opacity: 1;
    }
  }

  /* Media queries */

  @media screen and (max-width: 1150px){
    .Hero-content {
      max-width: 550px;
    }
    .Hero h1, .Hero-Loading h1 {
      font-size: 2.8rem;
    }
    .Hero h2, .Hero-Loading h2 {
      font-size: 1.4em;
      margin-bottom: 15px;
    }
    .Dynamic-Business-Listing-Count {
      text-align: left;
      line-height: 1em;
      margin-bottom: 5px;
    }
    .Hero .Hero-Register-Business h2, .Hero .Hero-Checkout-Business h2 {
      line-height: 1em;
    }
    .Hero-Search-Content {
      padding: 0 35px;
    }
    .Home .Hero-content h1 {
      font-size: 2.9rem;
    }
    .Home .Hero-content h2 {
      font-size: 1rem;
    }
    .Hero-Main-Section, .Hero-Loading, .Hero-Register-Business, .Hero-Checkout-Business {
      min-height: 480px;
    }
  }
  
  @media screen and (max-width: 980px){
    .Hero .Hero-Register-Business h2, .Hero .Hero-Checkout-Business h2 {
      font-size: 1.5em;
    }
    .Hero .Hero-Checkout-Business p {
      font-size: 18px;
    }
    .Hero .Hero-Checkout-Business a {
      font-size: 1.1em
    }
    .Dynamic-Business-Listing-Count {
      font-size: 18px;
    }
    .Hero-Register-Business input, .Hero-Register-Business select, .Hero-Register-Business button {
      font-size: 0.9em;
    }
    .Hero-Search-Content {
      min-height: unset;
    }
  }
  
  
  @media screen and (max-width: 880px){
    .Hero h1, .Hero-Loading h1 {
      font-size: 2.3rem;
    }
    .Hero h2, .Hero-Loading h2 {
      font-size: 1.3rem;
    }
    .Hero-content {
      max-width: 430px;
    }
    .Hero-Search-Content {
      padding: 0 25px;
    }
    .Hero-Register-Business, .Hero-Checkout-Business {
      padding-right: 0;
    }
  }
  
  @media screen and (max-width: 650px){
    /* HERO COLUMNS */
    .Hero-Main-Section, .Hero-Loading {
      min-height: 400px;
      flex: 65% 0 0;
    }
    .Hero-Register-Business, .Hero-Checkout-Business {
      min-height: 400px;
      flex: 35% 0 0;
    }
    /* HERO */
    .Hero-content {
      max-width: 310px;
    }
    .Hero h1, .Hero-Loading h1 {
      font-size: 1.75rem;
    }
    .Hero h2, .Hero-Loading h2 {
      font-size: 1.1rem;
    }
    .Hero .Hero-Checkout-Business p {
      font-size: 15px;
    }
    .Hero .Hero-Checkout-Business i {
      font-size: 50px;
    }
  }
  
  @media screen and (max-width: 570px){
    .Hero-Register-Business, .Hero-Checkout-Business {
      flex: 100% 0 0;
    }
    .Hero-Search-Content {
      flex: 100% 0;
    }
    .Hero-Search-Content {
      padding: 0 50px;
    }
    .Hero-Checkout-Business {
      flex: 100% 0 0;
    }
    .Hero .Hero-Checkout-Business i {
      font-size: 80px;
    }
    .Hero .Hero-Checkout-Business p {
      font-size: 18px;
    } 
    .Hero {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    .Hero-Main-Section, .Hero-Loading {
      flex: 100% 0 0;
    }
    .Hero-Register-Business, .BusinessFeature {
      width: 100%;
      flex: 100%;
    }
    .Hero-Register-Business, .Hero-Checkout-Business {
      padding: 0 20px;
    }
    .Home .Hero-Main-Section {
      padding: 50px 0;
    }
    
  }

  .hero-tool-search-container {
    display: flex;
    flex: 100% 0;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .hero-tool-search-container input {
    display: flex;
    flex: 85% 0;
    border-radius: 15px 0 0 15px;
    font-size: 20px;
    padding: 10px 25px;
    font-family: "Outfit";
    box-sizing: border-box;
  }
  
  .hero-tool-search-container button {
    display: flex;
    box-sizing: border-box;
    flex: 15% 0;
    background: #a2c860;
    color: #FFF;
    font-family: "Outfit";
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 0;
    font-size: 20px;
    border-radius: 0 15px 15px 0;
    cursor: pointer;
  }