/* Footer styles */
.footer {
    background-color: #651c8e;
    color: #fff;
    border-top: 10px solid #6d10a2;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px 70px;
    box-sizing: border-box;
  }

  .footer-container .footer-column i {
    margin-right: 6px;
  }
  
  .footer-column {
    flex: 25% 0 0;
    width: 25%;
    padding: 0 10px;
    box-sizing: border-box;
    flex-shrink: 0;
    text-align: left;
    font-family: "Outfit";
  }
  
  .footer-column h3 {
    font-size: 1.45rem;
    margin-bottom: 10px;
    line-height: 1em;
    display: flex;
    flex-wrap: nowrap;
    font-weight: 700;
    color: #FFF; /* Accent color for headings */
    align-items: center;
  }

  .footer-column h3 span {
    display: flex;
    flex: 2;
    font-size: 1.3rem;
    line-height: 1em;
  }

  .footer-container .footer-column h3 i {
    display: flex;
    background: #FFF;
    color: #651c8e;
    padding: 10px 12px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 10px;
  }
  
  .footer-column p,
  .footer-column ul {
    font-size: 1.1rem;
    line-height: 1.3;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 100%;
    width: 100%;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column li {
    margin: 3px 0;
    width: 100%;
  }
  
  .footer-column a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    flex: 100% 1;
    display: flex;
    align-items: center;
  }

  
  .footer-column a:hover {
    text-decoration: unset;
    opacity: 0.8;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 0;
    font-size: 1.23rem;
    font-family: "Outfit";
    padding: 10px 0;
    background: #e4ffb4;
    color: #b0b0b0;
    box-sizing: border-box;
    box-shadow: 0 -15px 0 #a2c860;
  }

  .footer-bottom p {
    color: #a2c860;
  }

  /* PAGE HEADER MEDIA QUERIES */

@media screen and (max-width: 1220px){

}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 980px){
  .footer-column {
    flex: 50% 0 0;
    width: 50%;
  }
}


@media screen and (max-width: 880px){

}

@media screen and (max-width: 750px){
  .footer-container {
    padding: 30px 10px 40px
  }
  .footer-column p, .footer-column ul {
    font-size: 16px;
  }
  .footer-container .footer-column h3 i {
    padding: 8px 10px;
  }
  .footer-column h3 span {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 500px){
  .footer-column p, .footer-column ul {
    font-size: 14px;
  }
  .footer-container .footer-column h3 i {
    padding: 5px 6px;
    font-size: 15px;
  }
  .footer-column h3 span {
    font-size: 1rem
  }
}

@media screen and (max-width: 450px){
  .footer-container .footer-column h3 i {
    display: inline;
    flex: 1 0 0;
    font-size: 15px;
    padding: 10px 12px;
  }
  .footer-container .footer-column h3 {
    display: inline-block;
    flex-wrap: wrap;
  }
  .footer-column p, .footer-column ul {
    margin: 5px 0;
  }
  .footer-container .footer-column span {
    width: 100%;
    flex: 100%;
    margin-top: 15px;
  }
  
}

@media screen and (max-width: 400px){
  .footer-column p, .footer-column ul {
    font-size: 13px;
  }
}

@media screen and (max-width: 380px){
  .footer-column {
    flex: 100%;
    width: 100%;
  }
}