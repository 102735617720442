.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 2rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .Header-mobile-menu {
    display: none;
  }

  .Header-logo a:hover {
    opacity: 0.6;
    transition: 0.25s opacity;
  }
  
  .Header-logo {
    display: flex;
    margin-bottom: -65px;
    z-index: 1;
    background: #FFF;
    border-radius: 0 0 20px 20px;
    flex: 15% 0;
    justify-content: center;
    border: 7px solid #eee;
      border-top-color: rgb(238, 238, 238);
      border-top-style: solid;
      border-top-width: 7px;
    border-top-color: rgb(204, 204, 204);
    border-top-style: solid;
    border-top-width: 2px;
    border-top: 0;
    box-shadow: 2px 2px 50px rgba(0,0,0,0.05);
    height: 100%;
    padding: 15px 0;
    margin-top: -60px;
  }

  .Header-logo img {
   float: left; 
   width: 50%;
  }

  .Header-logo span {
    height: 100%;
    position: relative;
    float: left;
    font-size: 24px;
    text-align: left;
    align-items: center;
    display: flex;
    font-family: "Outfit";
    line-height: 1em;
    color: #651c8e;
  }

  .Header-logo img {
    width: 60px;
    height: 60px;
    float: left;
  }
  
  .Header-nav {
    display: flex;
    gap: 2rem;
    font-weight: 600;
    flex: 85% 0 0;
    justify-content: right;
  }

  .Get-Started-Button { 
    padding: 25px 20px; 
    position: relative;
    background: #e4ffb4;
  } 

  .Get-Started-Button a:hover, .Login-Button a:hover {
    opacity: 0.5 !important;
    transition: 0.25s opacity ease-in-out !important;
  }

  .Get-Started-Button span { 
    position: relative;
    z-index: 10;
  } 

  .Header-nav li i {
    z-index: 1;
    color: #000;
    font-size: 24px;
    top: 5px;
    display: block;
    width: 100%;
    flex: 100% 0 0;
  }
  
  .Login-Button { 
    padding: 25px 20px; 
    background: #e4ffb4;
  }

  

  .Header-nav .Auth-container .Logged-In { 
    flex: 100% 0 0;
    padding: 10px 20px; 
    background: #e4ffb4;
    display: flex;
    align-items: center;
    border-left: 2px solid #a4ff021c;
  }

  .Logged-In .Business-Profile-Picture {
    display: flex;
    flex: 22% 0 0;
  }

  .Logged-In .Business-Profile-Picture .Business-Profile-Picture-Inner {
    background: #a2c860;
    width: 45px;
    height: 45px;
    float: left;
    border-radius: 50%;
  }

  .Logged-In .Manage-Business-Options {
    display: flex;
    flex: 78% 0 0;
    flex-wrap: wrap;
  }

  .Logged-In img {
    /*height: 100%;
    width: 100%;
    border-radius: 50%;*/
  }

  .Header-nav .Logged-In a {
    font-size: 16px;
    letter-spacing: 0.02rem;
    display: flex;
    flex: 100% 0 0;
  }

  .Header-nav .Logged-In .Manage-Business-Options a:hover {
    opacity: 1;
  } 

  .Header-nav .Logged-In a:hover {
    color: inherit;
  }

  .Logged-In .Manage-Business-Button {
    font-size: 16px;
    text-align: left;
    color: #555;
    font-family: "Outfit";
    font-weight: 500;
    display: flex;
    flex: 100% 0;
  }

  .Logged-In .Manage-Business-Options a {
    padding-left: 0;
  }

  .Logged-In .Manage-Business-Button i {
    display: flex;
    flex: 10% 0;
    font-size: 20px;
    padding-right: 2%;
    color: #000
  }

  
.Auth-container .Logged-In .Business-Profile-Picture,
.Auth-container .Logged-In .Manage-Business-Options,
.Auth-container .Get-Started-Button a,
.Auth-container .Login-Button a {
  animation: fadeInAnimation 1s forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
  }

  .Header-nav .Manage-Business-Menu {
    display: flex;
    flex: 90% 0;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    font-family: "Outfit";
    transition: transform 0.3s ease-in-out;
  }

  .Header-nav .Manage-Business-Menu:hover .fa-cog:before {
    animation: rotateIcon 5s linear infinite !important  
  }
  
    .Header-nav .Manage-Business-Menu:hover {
      animation: flashBackground 0.5s ease-in-out infinite;  
    }

    
  @keyframes rotateIcon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flashBackground {
    0%, 100% {
      color: #000;
    }
    50% {
      color: #656;
    }
  }
  
  .Header-nav .Manage-Business-Button .fa-sign-out {
    font-size: 14px;
    display: align-items;
    display: flex;
    align-items: center;
    padding-left: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: right;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .Header-nav .Manage-Business-Button .fa-sign-out:hover {
    opacity: 0.5;
  }

  


  .Header-nav .Auth-container {
    flex: 35% 0 0;
    display: flex;
    flex-wrap: nowrap;
  }

  .Header-nav .Auth-container li {
    flex: 50% 0 0;
    justify-content: center;
  }

  .Header-nav ul {
    list-style: none;
    display: flex;
    flex: 100% 0 0;
    margin: 0;
    flex-wrap: nowrap;
    justify-content: right;
  }

  .Header-nav li {
    padding: 20px 16px;
    flex: 8% 0 0;
    display: flex;
    position: relative;
    justify-content: center;
  }
  
  .Header-nav li a {
    color: #333;
    font-size: 1.2em;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    font-family: "Outfit";
    font-weight: 300;
    letter-spacing: 0.05em;
    padding-left: 3%;
  }

  .Header-nav li a:hover {
    opacity: 0.5;
    transition: 0.25s opacity ease-in-out;
    color: #000;
  }
  
  .Header li ul {
    position: absolute;
    top: 0;
    left: 0;
    background: #FAFAFA;
    width: 800px;
    display: none;
    margin: 0;
    margin-top: 0px;
    padding: 0;
    margin-top: 88px;
    border: 1px solid #DDD;
    border-radius: 0 0 20px 20px;
    border-bottom: 10px solid #eee;
  }



  .Header li:hover ul {
    display: flex;
    flex-wrap: wrap;
  }

  
  .Header li ul li {
    display: flex;
    flex: 33.3%;
    width: 33.3%;
    justify-content: flex-start;
  }

/* MOBILE MENU - POPOFF SCREEN */

.menu-toggle {
  font-size: 24px;
  cursor: pointer;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000; /* Ensure the button is above other content */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 998; /* Behind the mobile menu */
  transition: opacity 0.3s ease; /* Optional for fade effect */
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: -300px; /* Hidden off-screen */
  height: 100vh;
  width: 300px;
  background-color: #fff; /* Change as needed */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease; /* Transition effect */
  z-index: 999; /* Behind the toggle button */
  padding: 0;
  text-align: left;;
}

.mobile-menu img {
  width: 100%;
  height: 70px;
  object-fit: contain;
}

.mobile-menu.open {
  left: 0; /* Slide in */
}

.mobile-menu .menu-list li {
  padding: 5px 20px;
}

.mobile-menu .close-button {
  font-size: 30px;
  color: #333; /* Change as needed */
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.mobile-menu .logo {
  font-size: 24px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0;
  background: #FAFAFA;
}

.mobile-menu .logo a {
  width: 100%;
  flex: 100%;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  text-decoration: unset;
}

.mobile-menu .logo img {
  width: 40%;
}

.mobile-menu .logo span {
  width: 60%;
  font-family: "Outfit";
  text-decoration: unset;
  line-height: 1em;
}

.mobile-menu .menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu .menu-list li.sub-menu-list-item {
  background: #eee;
  box-sizing: border-box;
}

.mobile-menu .menu-list li.sub-menu-list-item a {
  padding-left: 20px;
  font-size: 15px;
}

.mobile-menu .sub-menu-list-heading {
  background: #515151;
  width: 100%;
  color: #FFF;
  display: flex;
  justify-content: center;
  padding: 3px 0;
  font-family: "Outfit";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}

.mobile-menu .menu-list li {
  margin: 15px 0;
  font-size: 18px;
}



.mobile-menu .menu-list li {
  margin: 0;
  padding: 0;
  width: 100%;
}

.mobile-menu .menu-list li:first-child {
  border-top: 1px solid #DDD;
}

.mobile-menu .menu-list li a {
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #DDD;
  width: 100%;
  flex: 100%;
  display: flex;
  color: #333;
}

.mobile-menu .menu-list li a:hover {
  background: #DDD;
}

.mobile-menu .menu-list li a {
  text-decoration: unset;
  line-height: 1em;
}

.mobile-menu .menu-list li i {
  margin-right: 10px;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1250px){
  .Header-nav .Manage-Business-Menu {
    font-size: 17px;
    align-items: center;
  }
  .Logged-In .Manage-Business-Button i {
    font-size: 16px;
  }
}

@media screen and (max-width: 1150px){
  /* HEADER GRID */
  .Header-logo {
    flex: 20% 0 0;
  }
  .Header-nav {
    flex: 80% 0 0;
  }
  /* HEADER INNER */
  .Header-nav .Auth-container {
    flex: 45% 0 0;
  }
  .Header-nav li a {
    font-size: 1.1em;
  }
  .Header-logo span {
    font-size: 21px;
  }
}

@media screen and (max-width: 980px){
  /* HEADER NAV */
  .Header-nav .Auth-container {
    flex: 48% 0;
  }
  .Header-nav li a {
    font-size: 16px;
  }
  .Header-nav li {
    padding: 20px 10px;
    flex: 12% 0 0;
  }
  .Header-logo span {
    font-size: 18px;
  }
  .Header-nav .Manage-Business-Menu {
    font-size: 15px;
  }
}


@media screen and (max-width: 880px){
    /* HEADER GRID */
    .Header-logo {
      flex: 25% 0 0;
      margin-bottom: -70px;
    }
    .Header-nav {
      flex: 75% 0 0;
    }
    .Logged-In .Business-Profile-Picture .Business-Profile-Picture-Inner {
      height: 35px;
      width: 35px;
    }
    .Header-nav .Auth-container .Logged-In {
      padding: 10px 12px;
    }
    .Logged-In .Business-Profile-Picture {
      flex: 18% 0;
    }

    .Logged-In .Manage-Business-Options {
      flex: 82% 0;
    }

    .Logged-In .Manage-Business-Button i {
      font-size: 14px;
      padding-right: 0;
    }
    
    .Header-nav .Manage-Business-Menu {
      font-size: 14px;
    }

    .Header-nav .Manage-Business-Options {
      padding-left: 2px;
    }
}

@media screen and (max-width: 750px){
  .Header {
    padding: 0;
  }
  .Header-mobile-menu {
    display: flex;
    font-size: 45px;
    padding: 10px 20px;
    flex: 50% 0 0;
    justify-content: right;
  }
  .Header-mobile-menu i:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .Header-nav {
    display: none;
  }
  .Header-logo {
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    flex: 50% 0 0;
    justify-content: left;
  }
}

@media screen and (max-width: 350px){
  .mobile-menu {
    left: -500px;
    width: 100%;
  }
}

@media screen and (max-width: 320px){
  .Header-logo span {
    display: none;
  }
}