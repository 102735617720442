.news-item-inner-image { 
  width: 100%;
  flex: 25% 0;
  height: auto;
  background: #EEE;
  border: 2px solid #CCC;
  border-radius: 5px 0 0 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 200px;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

.news-item-inner-image img {
  object-fit: cover;
  display: flex;
  height: 100%;
}

.news-item-inner-image i {
  font-size: 40px;
}

.news-item-inner-details-container p {
  margin: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  text-align: left;
  font-family: "Outfit";
}

.news-item-inner-details-container a {
  color: #000;
  margin-top: 5px;
  display: inline-flex;
  font-family: "Outfit";
  padding: 4px 9px;
  text-decoration: unset;
  position: absolute;
  bottom: 3px;
  right: 3px;
  font-size: 15px;
  line-height: 1em;
  align-items: flex-end;
}

.news-item-inner-details-container {
  flex: 75% 0;
  padding: 20px 25px 50px 25px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  text-align: left;
  position: relative;
  text-transform: capitalize;
  background: #FFF;
  border-radius: 0 10px 10px 0;
  border: 2px solid #ddd;
  border-left: 0;
}

.news-item-inner-details-container h2 {
  margin: 0;
    margin-top: 0px;
  font-size: 20px;
  font-family: "Outfit";
  text-align: left;
  line-height: 1.1em;
}

/* BusinessItem.css */

.business-item.news-item {
  flex: 100% 0;
  padding: 0 5px 10px;
  border-bottom: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  padding-right: 5px;
  }

  .business-item.news-item .news-item-images p {
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 15px;
      background:
    #FFF;
      margin:
    15px;
      border-radius:
    50px;
      font-family: "Outfit";
      padding:
    10px !important;
      line-height: 1em;
      color: #000;
      border:
    4px solid #651c8e3d;
 }

 .tool-item-inner-details-container {
  font-family: "Outfit";
 }

 /* News */

 /* News box - icon tabs */

 .news-item-tabs {
  display: flex;
  width: 100%;
}

.news-tab-column-left {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EEE;
  border-radius: 15px;
  border: 1px solid #DDD;
  padding: 3px 7px;
  margin-right: 10px;
  opacity: 0.2;
  cursor: not-allowed
}


.news-tab-column-right {
  flex: 10;
  display: flex;
  background: #EEE;
  border: 1px solid #DDD;
  border-radius: 15px;
}

.business-item p.news-author {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  color: #555;
  margin: 0.25rem 0 0.5rem;
  border: 0;
}

.news-author-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ccc; /* placeholder gray */
  margin-right: 8px;
}

.news-author-name {
  font-weight: 500;
}


.vote-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upvote, .downvote {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

.vote-count {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  font-weight: bold;
}

.upvote:hover {
  color: #62bb48;
}

 .downvote:hover {
  color: #e83737;
 }


  .tool-item-inner-details-container.news-item-details { padding: 20px 25px 30px; }

  .tool-item-inner-details-container.news-item-details a {
    position: unset;
    position: unset;
    margin: unset;
    font-size: unset;
    padding: 0;
  }

  .tool-item-inner-details-container.news-item-details a:hover {
    opacity: 0.7;
  }
 
.news-tab {
  display: flex;
  align-items: center;
}

.news-tab i {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.news-tab span {
  font-size: 16px;
}

/* Additional styling for better alignment and spacing */
.news-item-tabs .news-tab-column-left .vote-section {
  align-items: center;
}

.news-item-tabs .news-tab-column-right .news-tab {
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  box-sizing: border-box;
}

.news-item-tabs .news-tab-column-right .news-tab span {
  font-size: 14px;
  line-height: 1em;
}


/* News - Featured image */

  .business-item.news-item img {
    width: 100%;
    border-radius: 10px 0 0 10px;
  }

  .business-item-inner.news-item-inner {
    display: flex;
    flex-wrap: wrap;
  }

  .business-item .plan-detail {
    color: #62bb48;
    margin-top: 8px;
    color: #651c8e;
    border-bottom: 2px solid #eee;
    margin-bottom: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    font-family: "Outfit";
    font-weight: 700;
  }

  .business-item .plan-detail .price {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 4px 11px;
    border-radius: 10px;
    color: #333;
    font-family: "Outfit";
    font-weight: 700;
    font-size: 16px;
    border: 3px solid #eee;
    text-transform: none;
  }

  .business-item .plan-detail.complete,
  .business-item .plan-detail.complete i,
  .business-item .plan-detail.all-plans,
  .business-item .plan-detail.all-plans i  {
    color: #651c8e;
  }

  .business-item .plan-detail.free,
  .business-item .plan-detail.free i {
    color: #62bb48;
  }

  .business-item .plan-detail i {
    color: #62bb48;
    font-size: 18px;
    margin: 0;
  }

  .business-item .tool-subtitle {
    font-size: 16px;
    line-height: 1.2em;
    text-transform: none;
  }



  .business-item-inner-details a {
    color: #000;
    margin-top: 5px;
    display: inline-flex;
    font-family: "Outfit";
    padding: 4px 9px;
    text-decoration: unset;
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 15px;
    line-height: 1em;
    align-items: flex-end;
  }

  .business-item-inner-details a:hover { 
    color: #651c8e 
  } 

  .news-item-details .news-excerpt {
    border: 0;
  }

  .business-item-inner-details a i {
    font-size: 12px;
    margin-left: 4px;
  }

  .business-item-inner-image.news-item-images {
    width: 100%;
    flex: 40% 0;
    height: auto;
    background: #EEE;
    border: 2px solid #CCC;
    border-radius: 5px 0 0 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 200px;
    box-sizing: border-box;
  }

  .news-item-images.business-item-inner-image {
    flex: 25%;
    background-size: cover;
    background-position: center center;
    position: relative;
  }
  
  .business-item-inner-image.news-item-images img {
    max-height: 200px;
    object-fit: cover;
    align-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .business-item-inner-image i {
    font-size: 40px;
  }

  .business-item-inner-details h2 {
    margin: 0;
    font-size: 20px;
    font-family: "Outfit";
    text-align: left;
    line-height: 1.1em;
  }

  .business-item-inner-details p {
    margin: 0;
    text-align: left;
    font-family: "Outfit";
  }

  .business-item-inner-details p i {
    font-size: 14px;
    margin-right: 2px;
    color: #000;
  }
  
  .business-item img {

  }

  
  .business-item h2 {
    margin-top: 10px;
    font-size: 1.5em;
  }
  
  .business-item p {
    margin-top: 5px;
    color: #555;
    border-bottom: 1px solid #fafafa;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  /* Loading */

.business-item {
  animation: fadeInAnimation 0.5s;
}



  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
  }

  .mobile-plan-details {
    display: none;
  }








/* PAGE HEADER MEDIA QUERIES */

@media screen and (max-width: 1220px){
  .news-item-tabs .news-tab-column-right .news-tab span {
      font-size: 14px;
      line-height: 1em;
  }
  .news-tab i {
      font-size: 16px;
  }
  .news-item-tabs .news-tab-column-right .news-tab {
      padding: 0 8px;
  }
  .news-tab i {
      margin-bottom: 5px;
  }
}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 980px){
  .news-item-tabs .news-tab-column-right .news-tab {
    flex: 50% 0 0;
    width: 50%;
  }
  .news-tab-column-right {
    flex-wrap: wrap;
  }
  .news-tab i {
    margin-bottom: 0;
    text-align: left;
    justify-content: flex-start;
    margin-right: 7px;
  }
  .news-tab-column-right {
    padding: 10px 12px;
  }
  .news-item-tabs .news-tab-column-right .news-tab span {
    font-size: 14px;
    line-height: 1em;
    flex: 2;
    justify-content: flex-start;
    text-align: left;
  }
  .news-item-tabs .news-tab-column-right .news-tab {
    margin-bottom: 4px;
  }
  .news-item-tabs .news-tab-column-right .news-tab i {
    flex: 0;
  }
}


@media screen and (max-width: 880px){

}

@media screen and (max-width: 750px){

}

@media screen and (max-width: 650px) {
  .tool-item-inner-details-container.news-item-details p,
  .tool-item-inner-details-container.news-item-details a {
    display: flex;
  }
  .news-item-images.business-item-inner-image,
  .tool-item-inner-details-container.news-item-details {
    flex: 100%;
  }
  .news-item-images.business-item-inner-image {
    border: 0;
    border-radius: 15px 15px 0 0;
  }
  .tool-item-inner-details-container.news-item-details {
    flex-wrap: wrap;
    border-radius: 0 0 15px 15px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
  }
}

@media screen and (max-width: 320px){

}














  @media screen and (max-width: 750px){
    .business-item.news-item {
      padding-left: 0;
      padding-right: 0;
    }
    .business-item.news-item {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 650px){
    .news-item-inner-details-container p,
    .news-item-inner-details-container a {
      display: none;
    }
    .news-item-inner-details-container .plan-detail {
      display: flex;
      margin: 0;
      margin-top: 3px;
      padding: 0;
      font-size: 14px;
      border: 0;
    }
    .news-item-inner-details-container .plan-detail i {
      margin-right: 3px;
      font-size: 14px;
    }
    .business-item.news-item img {
      object-fit: contain;
      object-position: top;
      border-radius: 10px 0 0 0;
      background: #DDD;
      min-height: 85px;
    }
    .news-item-inner-image {
      min-height: unset;
    }
    .mobile-plan-details {
      display: flex;
      flex-wrap: wrap;
      background: #FFF;
      border-radius: 0 0 10px 10px;
      border-top: 0;
      padding: 15px;
      border: 2px solid #BBB;
      border-top: 0;
      box-sizing: border-box;
      text-align: left;
      font-family: "Outfit";
      justify-content: flex-end;
    }
    .mobile-plan-details .tool-subtitle {
      text-align: left;
      border: 0;
    }
    .news-item-inner-details-container {
      align-items: center;
      background: #FAFAFA;
      align-content: center;
      display: flex;
      padding: 0;
      text-align: center;
      justify-content: flex-start;
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
      border-radius: 0 10px 0 0;
      border-top: 2px solid #bbb;
      border-right: 2px solid #bbb;
      border-bottom-width: 2px;
    }
    .mobile-plan-details a i {
      font-size: 12px;
      margin-left: 3px;
    }
    .mobile-plan-details a {
      color: #000;
      text-decoration: unset;
      font-family: "Outfit";
      padding: 4px 18px;
      border-radius: 10px;
      color:#FFF;
      font-size: 15px;
      background: #6fb75a;
      display: flex;
      align-items: center;
    }
    .news-item-inner-image {
      border: 0;
      border-radius: 10px 0 0 0;
      border-top: 2px solid #BBB;
      border-left: 2px solid #BBB;
    }
    .news-item-inner-image img {
      border-radius: 10px 0 0 0;
    }
    .business-item .plan-detail .price {
      font-size: 12px;
      padding: 3px 9px;
      top: 3px;
      right: 3px;
      border-width: 1px;
      color: #444;
    }
    .business-item .tool-subtitle {
      font-size: 14px;
    }
    .business-item.news-item {
      padding: 0 0 10px;
      border-bottom: 2px dotted #ccc;
      margin-bottom: 10px;
      }
  }
